:root {
    --gradient-start: #3482db;
    --gradient-end: #3cacac;
    --text-color: #ffffff;
    --cta-bg-color: #7f8c8d;
    --cta-text-color: #ffffff;
    --feature-bg-color: rgba(255, 255, 255, 0.1);
    --feature-text-color: #ffffff;
    --footer-bg-color: rgba(255, 255, 255, 0.05);
  }

.feedback-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - var(--navbar-height));
    background: linear-gradient(to right bottom, var(--gradient-start), var(--gradient-end));
}

.feedback-container {
    background: rgba(255, 255, 255, 0.8);
    color: var(--text-color);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 500px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.feedback-title {
    font-size: 2rem;
    color: grey;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.feedback-form {
    width: 100%; 
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.feedback-input,
.feedback-button {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px; 
}

.feedback-input {
    background-color: var(--feature-bg-color);
    color: black;
}

.feedback-button {
    background-color: var(--cta-bg-color);
    color: var(--cta-text-color);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem; 
}

.feedback-button:hover {
    background-color: #1d7874;
}


.feedback-textarea {
    height: 100px; 
    resize: none; 
}
