.editor-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - var(--navbar-height));
    background: linear-gradient(to right bottom, var(--gradient-start), var(--gradient-end));
  }
  
  .editor-container {
    background: rgba(255, 255, 255, 0.8);
    color: black;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
  }

  button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: var(--cta-bg-color); 
    color: var(--cta-text-color); 
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 5px; 
  }
  
  .m-title {
    font-size: 1.5rem;
    color: grey;
    margin-bottom: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .content-list {
    list-style-type: none;
    padding: 0;
  }
  
  .content-item {
    background-color: rgb(204, 201, 201);
    color: black;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
  }
  

  .delete-content-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .delete-content-form {
    background: rgba(255, 255, 255, 0.9);
    color: black;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
  }
  
  .delete-content-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: var(--cta-bg-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-content-input,
  .delete-content-select,
  .delete-content-submit {
    width: 100%;
    padding: 8px 12px;
    margin: 8px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .delete-content-submit {
    background-color: var(--cta-bg-color);
    color: white;
    cursor: pointer;
  }
  