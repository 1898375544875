.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.delete-content-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px; 
}

.delete-content-form h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.delete-content-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.delete-content-form form label {
    margin-bottom: 5px;
    color: #333;
}

.delete-content-form form input,
.delete-content-form form select {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px; 
}

.delete-content-form form button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.delete-content-form form .submit-button {
    background-color: #4CAF50;
    color: white;
}

.delete-content-form form .cancel-button {
    background-color: #f44336;
    color: white;
}

@media (max-width: 600px) {
    .delete-content-form {
        width: 80%;
    }
}
