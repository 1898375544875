.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.add-content-form {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
    max-width: 500px;
}

.add-content-form label {
    font-size: 1rem;
    color: black;
    margin-bottom: 5px;
}

.add-content-form input,
.add-content-form select,
.add-content-form button {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
}

.add-content-form button {
    background-color: var(--cta-bg-color);
    color: var(--cta-text-color);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-content-form button:hover {
    background-color: #1d7874;
}

.close-button {
    align-self: flex-end;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 1.5rem;
    color: #333;
}


.add-content-form select {
    background-color: var(--feature-bg-color);
    color: black;
}

.add-content-form input[type="file"] {
    background-color: var(--feature-bg-color);
    color: black;
}
