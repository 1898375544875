.video-player-container {
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
  height: calc(100vh - 150px);
  background-color: #000; 
  position: relative; 
}


.video-player {
  max-height: 100%; 
  width: 100%; 
  max-width: 800px; 
}

