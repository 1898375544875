.activity-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  overflow-x: hidden;
}

.pdf-and-download-container {
  flex: 3; 
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  padding: 15px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}



.ai-viewer-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 90vh;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 255, 0.2);
  padding: 15px;
  margin: 10px;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.prompt-input {
  resize: none;
  border: 1px solid #b0c4de;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 1rem;
}

.ai-response {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 5px 0;
  font-weight: normal;
  color: #4a4a4a;
}

.user-prompt {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 5px 0;
  font-weight: bold;
  color: #224870;
}

.responses-container {
  overflow-y: auto;
  margin-bottom: 10px;
}

.response {
  background: linear-gradient(to bottom right, #dae8fc, #c8d7eb);
  color: #1a1a1a;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #b0c4de;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
}

.response:hover {
  background: linear-gradient(to bottom right, #d0e0fc, #b8cfe8);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.prompt-form {
  display: flex;
  flex-direction: column;
}

.download-button, .submit-button {
  background: #d3d3d3;
  color: #333;
  border-radius: 4px;
  border: none;
  padding: 10px;
  margin-top: 5px;
  cursor: pointer;
  font-weight: bold;
}

.download-button:hover, .submit-button:hover {
  background: #a9a9a9;
}

.activity-container.ai-absent .pdf-and-download-container {
  flex: 0 0 auto;
  width: 960px;
  height: auto;
  max-height: 720px;
  margin: auto;
}

@media (max-width: 768px) {
  .activity-container.ai-absent .pdf-and-download-container {
    width: 100%;
    height: auto;
    max-height: 100%;
  }
}

@media (max-width: 768px) {
  .activity-container {
    flex-direction: column;
  }

  .pdf-and-download-container, .ai-viewer-container {
    width: 100%;
    margin: 5px 0;
  }
}
