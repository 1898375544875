.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 32px);
    background: linear-gradient(to right bottom, var(--gradient-start), var(--gradient-end));
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
}

.profile-title {
    font-size: 3rem;
    color: var(--cta-text-color);
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
}

.profile-info {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 400px;
    margin-bottom: 10px;
}

.profile-info p {
    font-size: 1.2rem;
    margin: 10px 0;
    color: black;
    line-height: 1.6;
}

.password-change-container {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 400px;
    margin: 10px 0;
    
}

.password-change-title {
    font-size: 2rem;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
}

.toggle-password-visibility {
    background: none;
    border: none;
    color: darkslategray;
    cursor: pointer;
}

.password-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.password-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.password-form input {
    font-size: 1rem;
    padding: 10px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.password-form button {
    padding: 10px;
    background-color: darkgrey;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: background-color 0.3s;
}

.password-form-button:hover {
    background-color: darkslategrey;
}

.error-message {
    color: #d32f2f;
}

.error-message, .success-message {
    color: var(--error-color);
    font-size: 0.9rem;
}

.success-message {
    color: #388e3c;
}