.user-form {
    position: absolute;
    top: 60px;
    right: 20px; 
    background-color: rgba(255, 255, 255, 0.9); 
    border: none; 
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    z-index: 100; 
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px; 
}

.user-form button {
    margin: 5px;
    padding: 10px 15px; 
    border: none; 
    background-color: var(--cta-bg-color); 
    color: var(--cta-text-color); 
    cursor: pointer;
    border-radius: 5px; 
    transition: background-color 0.3s ease; 
    text-transform: uppercase; 
    font-weight: bold; 
}

.user-form button:hover {
    background-color: #1d7874; 
}
