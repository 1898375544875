.forgot-user-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - var(--navbar-height));
    background: linear-gradient(to right bottom, var(--gradient-start), var(--gradient-end));
}

.forgot-user-container {
    background: rgba(255, 255, 255, 0.8);
    color: var(--text-color);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 500px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forgot-user-message {
    color: black;
}

.forgot-user-title {
    font-size: 2rem;
    color: grey;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.forgot-user-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: black;
}

.forgot-user-input,
.forgot-user-button {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
}

.forgot-user-input {
    background-color: var(--feature-bg-color);
    color: black;
}

.forgot-user-button {
    background-color: var(--cta-bg-color);
    color: var(--cta-text-color);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1rem;
}

.forgot-user-button:hover {
    background-color: #1d7874;
}


